.form {
    $this: &;

    padding: 30px;
    background-color: $color-white;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    &__group {
        & + & {
            margin-top: 20px;
        }
    }

    &__row {
        margin-bottom: 15px;

        @include tablet {
            display: flex;
        }

        &--with-submit-btn {
            margin-top: 40px;
            margin-bottom: 10px;

            @include tablet {
                margin-bottom: 0;
            }
        }
    }

    &__col {
        @for $i from 1 through 12 {
            &--#{$i} {
                @include tablet {
                    width: 100% * ($i / 12);
                }
            }
        }

        & + & {
            margin-top: 20px;

            @include tablet {
                margin-top: 0;
                margin-left: 30px;
            }
        }
    }
}
