.form-textarea {
    $this: &;

    position: relative;
    display: block;

    &__control {
        width: 100%;
        min-height: 100px;
        padding: 20px 14px;
        border: 1px solid $color-light-grey;
        border-radius: 5px;
        font-size: 14px;
        resize: none;
        transition: border-color $transition-default;
        appearance: none;

        @include placeholder {
            color: $color-light-grey;
        }

        .is-invalid & {
            border-color: $color-danger;
        }

        @include hover-focus {
            border: 1px solid rgba(42, 42, 42, 0.6);
        }
    }

    &__validity-state-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        pointer-events: none;
    }
}
