// get numbers from map that was loaded via sass-vars-loader
$breakpoints: (
    'tablet': 768px,
    'desktop': 1024px,
    'desktop-wide': 1600px,
);

@each $size, $value in $breakpoints {
    @custom-media --#{$size} (width >= #{$value});
}

@custom-media --only-mobile (width < #{map-get($breakpoints, tablet)});
@custom-media --only-tablet (width >= #{map-get($breakpoints, tablet)}) and (width < #{map-get($breakpoints, desktop)});
@custom-media --only-desktop (width >= #{map-get($breakpoints, desktop)}) and (width < #{map-get($breakpoints, desktop-wide)});

@custom-media --until-desktop (width < #{map-get($breakpoints, desktop)});

@mixin mobile-only {
    @media (--only-mobile) {
        @content;
    }
}

@mixin tablet {
    @media (--tablet) {
        @content;
    }
}

@mixin tablet-only {
    @media (--only-tablet) {
        @content;
    }
}

@mixin desktop {
    @media (--desktop) {
        @content;
    }
}

@mixin desktop-only {
    @media (--only-desktop) {
        @content;
    }
}

@mixin desktop-wide {
    @media (--desktop-wide) {
        @content;
    }
}

@mixin print {
    @media print {
        @content;
    }
}

@mixin container-less-than-tablet {
    @container (width < #{$screen-tablet}) {
        @content;
    }
}

@mixin until-desktop {
    @media (--until-desktop) {
        @content;
    }
}

@mixin accessability-reduce {
    @media (prefers-reduced-motion: reduce) {
        @content;
    }
}
