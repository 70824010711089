.visually-hidden {
    @include visually-hidden;
}

.hidden {
    display: none;
}

@each $size, $value in $breakpoints {
    .show-if-#{$size} {
        display: none;

        @media (--#{$size}) {
            display: block;
        }
    }
}

@each $size, $value in $breakpoints {
    .hide-if-#{$size} {
        @media (--#{$size}) {
            display: none;
        }
    }
}

.text-align-center {
    text-align: center;
}
