.modal {
    $this: &;
    $button-width: 18px;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: $layer-modal;
    display: none;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);

    @include tablet {
        padding: 30px;
    }

    @include desktop {
        padding: 80px 60px;
    }

    &__wrapper {
        position: relative;
        width: 100%;
        max-width: $container-max-width;
        margin: auto;
        margin-top: 0;
    }

    &__content {
        overflow-x: hidden;
        width: 100%;
        background-color: $color-white;
        border-radius: 5px;
    }

    &__close {
        @include reset-button();

        position: absolute;
        top: 40px;
        right: 40px;
        z-index: 1;
        width: $button-width;
        height: $button-width;
        color: $color-dark-gray;
        cursor: pointer;
    }

    &.is-opened {
        display: flex;
    }
}
