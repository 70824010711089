// spaces values
$spaces: (0, 5, 10, 15, 20, 25, 30, 40, 50, 60, 80, 100, 120, 280);

// spaces CSS properties
$space-prefixes: (
    p: padding,
    pt: 'padding-top',
    pb: 'padding-bottom',
    m: margin,
    mt: 'margin-top',
    mb: 'margin-bottom',
);

@mixin make-spaces($spaces, $prefixes, $breakpoint: 'initial') {
    @each $attr-short, $attr-long in $prefixes {
        @each $value in $spaces {
            // on mobile all spaces are 0 by default

            @if ($breakpoint == 'mobile' and $value == 0) {
                // do nothing
            }

            @else {
                @if ($attr-short == 'p' or $attr-short == 'm') {
                    .#{$breakpoint}-#{$attr-short}-#{$value} {
                        #{$attr-long}-top: #{$value/16 + rem};
                        #{$attr-long}-bottom: #{$value/16 + rem};
                    }
                }

                @else {
                    .#{$breakpoint}-#{$attr-short}-#{$value} {
                        #{$attr-long}: #{$value/16 + rem};
                    }
                }
            }
        }
    }
}

@include make-spaces($spaces, $space-prefixes);

@each $breakpoint, $value in $breakpoints {
    @media (--#{$breakpoint}) {
        @include make-spaces($spaces, $space-prefixes, $breakpoint);
    }
}
