.nav {
    $this: &;

    padding: 70px 25px;

    &__link {
        display: flex;
        align-items: center;
        padding: 15px 0;
        color: $color-dark-gray;
        font-size: 18px;
        letter-spacing: -0.002em;
        text-decoration: none;

        &.is-active {
            color: $color-red;

            #{$this}__icon {
                color: $color-red;
            }
        }
    }

    &__icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
}
