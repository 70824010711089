.form-select {
    $this: &;

    position: relative;

    &__control {
        width: 100%;
        padding: 10px 15px;
        padding-right: 45px;
        border: 1px solid $color-light-grey;
        border-radius: 5px;
        font-size: 14px;
        transition: border-color $transition-default;
        appearance: none;

        .is-invalid & {
            border-color: $color-danger;
        }

        .is-invalid &,
        .is-valid & {
            padding-right: 60px;
        }

        &:invalid {
            color: $color-light-grey;
        }

        @include hover-focus {
            border: 1px solid rgba(42, 42, 42, 0.6);
        }

        &::-ms-expand {
            display: none;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        right: 22px;
        transform: translateY(-50%);
        pointer-events: none;
        fill: $color-light-grey;
    }
}
