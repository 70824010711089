.order-panel {
    $this: &;

    &__header {
        @include desktop {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__description {
        font-size: 18px;
    }

    &__buttons {
        margin-top: 20px;

        @include desktop {
            margin-top: 0;
            margin-left: 20px;
        }
    }

    &__btn {
        & + & {
            margin-left: 30px;
        }
    }
}
