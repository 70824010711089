.user-info {
    $this: &;

    display: flex;
    align-items: center;

    &__media {
        @include objectFitImageParent();

        position: relative;
        overflow: hidden;
        width: 32px;
        height: 32px;
        margin-right: 10px;
        border-radius: 50%;
    }

    &__data {
        font-size: 12px;
        line-height: 1;
    }

    &__name {
        font-size: 14px;
        letter-spacing: 0.05em;
    }

    &__position {
        margin-top: 2px;
        color: $color-light-grey;
        font-weight: 700;
        letter-spacing: 0.05em;
    }

    &__logout {
        margin-top: 2px;
    }
}
