$grid-columns: 12;
$grid-gutter: 30px;
$grid-gutter-mobile: 15px;

$transition-default: 0.3s linear;
$transition-ease-out-expo: 0.3s easeOutExpo;
$motion-easing: cubic-bezier(0.4, 0.47, 0.17, 0.98);
$transition-motion: 0.3s $motion-easing;

$default-border-width: 1px;

$default-mobile-side-padding: 20px;
$default-tablet-side-padding: 60px;
$default-desktop-side-padding: 60px;
$default-desktop-wide-side-padding: 65px;

$modal-media-caption-gap: 38px;

$fixed-container-max-widths-map: (
    'tablet': 688px,
    'desktop': 964px,
    'desktop-wide': 1310px,
);

$container-max-width: 1350px;
$container-tiny-max-width: 430px;
$container-small-max-width: 765px;
$container-medium-max-width: 964px;

$layer-alert-bar-container: 90;
$layer-header: 100;
$layer-element-spinner: 100;
$layer-cookie-bar: 200;
$layer-overlay: 300;
$layer-menu: 500;
$layer-modal: 700;
$layer-prompt: 600;
$layer-page-spinner: 1000;
