.footer {
    $this: &;

    padding: 30px;
    background-color: $color-white;
    font-size: 14px;

    &__container {
        display: flex;
        align-items: center;
    }

    &__copy {
        line-height: 1;
    }

    &__icon {
        margin-right: 5px;
    }
}
