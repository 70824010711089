*,
*::before,
*::after {
    box-sizing: border-box;
}

[data-whatintent='mouse'] *:focus,
[data-whatintent='touch'] *:focus {
    outline: none;
}

.html {
    position: relative;
    overflow-y: scroll;
    height: 100%;
    font-size: 100%;

    &.is-blocked {
        overflow: hidden;
    }

    &.is-blocked-touch {
        position: fixed;
        overflow-y: scroll;
        width: 100%;
        height: auto;
    }
}

.body {
    height: 100%;
    margin: 0;
    color: $color-black;
    font-family: $ff-circe;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
}

.container {
    max-width: $container-max-width;
    margin-right: auto;
    margin-left: auto;
}

.container-tiny {
    max-width: $container-tiny-max-width;
    margin-right: auto;
    margin-left: auto;
}


.container-small {
    max-width: $container-small-max-width;
    margin-right: auto;
    margin-left: auto;
}

.container-medium {
    max-width: $container-medium-max-width;
    margin-right: auto;
    margin-left: auto;
}

.main {
    $this: &;

    display: flex;

    &__sidebar {
        position: sticky;
        top: 0;
        flex-shrink: 0;
        width: 250px;
        height: 100vh;
    }

    &__sidebar-logo {
        padding: 15px 20px;
        border-bottom: 1px solid $color-grey;

        img {
            width: 140px;
            height: 50px;
        }
    }

    &__content {
        width: 100%;
    }

    &__content-inner {
        overflow: hidden;
        min-height: calc(100vh - 80px - 75px);
        padding: 50px 30px 80px 30px;
        background-color: $color-grey;
    }

    &__content-logo {
        display: block;
        margin-right: auto;
        margin-left: auto;
    }

    &--login {
        #{$this}__content-inner {
            min-height: calc(100vh - 75px);
            padding-top: 30px;
        }
    }
}

.hr {
    &::before {
        content: '';
        display: block;
        border-top: 1px solid $color-light-grey;
    }
}

.table-wrapper {
    position: relative;
    overflow-x: auto;
    min-height: 100px;
    white-space: nowrap;
}

.table {
    $this: &;

    width: 100%;
    min-width: 100%;
    border-collapse: collapse;

    th,
    td {
        padding: 15px 30px;
        font-size: 18px;
        text-align: left;
    }

    thead {
        tr {
            background-color: $color-white;
        }

        th {
            position: sticky;
            top: 0;
            z-index: 1;
            background-color: $color-white;
        }

        th,
        td {
            font-size: 14px;
            font-weight: 700;
        }
    }

    tbody {
        td {
            &:first-of-type {
                font-weight: 700;
            }
        }

        tr {
            background-color: $color-grey;
            transition: background-color $transition-default;

            @include hover-focus {
                background-color: $color-grey-4;
            }
        }
    }

    &--type-2 {
        thead {
            th,
            td {
                padding: 15px;
            }

            tr,
            th {
                background-color: transparent;
            }

            tr {
                border-bottom: 1px solid $color-light-grey;
            }
        }

        th,
        td {
            padding: 20px 15px;
        }

        a:not([class]),
        button:not([class]) {
            color: inherit;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.05em;

            @include hover-focus {
                color: $color-red-hover;
                text-decoration: underline;
            }
        }

        tbody {
            tr {
                border-top: 1px solid $color-grey-5;
                transition: border-color $transition-default;

                @include hover-focus {
                    border-top-color: transparent;

                    + tr {
                        border-top-color: transparent;
                    }
                }
            }

            td {
                &:last-of-type {
                    width:40%;
                }
            }
        }
    }

    &--orders {
        tbody {
            td {
                &:last-of-type {
                    width: auto;
                    text-align: right;
                }
            }
        }
    }
}
