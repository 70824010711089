/* general mixins and helpers */

@mixin reset-list() {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin reset-button() {
    padding: 0;
    background-color: transparent;
    border: none;
}

@mixin objectFitImageParent($fit: cover) {
    img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: $fit;

        @content;
    }
}

@mixin hover-focus() {
    .can-hover &:hover,
    [data-whatintent='keyboard'] &:focus {
        @content;
    }
}

@mixin keyboardFocus() {
    [data-whatintent='keyboard'] & {
        @content;
    }
}

@mixin hover() {
    .can-hover &:hover {
        @content;
    }
}

@mixin richtext() {
    .richtext {
        @content;
    }
}

@mixin reset-blockquote() {
    margin: 0;
}

@mixin reset-figure() {
    margin: 0;
}

@mixin ie {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

@mixin visually-hidden() {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    white-space: nowrap;
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

@mixin text-link {
    color: $color-red;
    font-size: 14px;
    text-decoration: underline;
    transition: color $transition-motion;

    @include hover {
        text-decoration: none;
    }

    &:disabled,
    &.is-disabled {
        color: $color-light-grey;
    }
}

@mixin text-link-dashed {
    @include text-link();

    text-decoration-style: dashed;
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
    // For Google Chrome
    &::-webkit-scrollbar {
        width:  $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        background: $background-color;
        border-radius: 10px;
    }

    // For Internet Explorer
    & {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}
