.status {
    $this: &;

    display: inline-flex;
    padding: 5px 7px;
    border-radius: 5px;
    color: $color-white;
    font-size: 14px;
    line-height: 1;

    &--pending {
        background-color: $color-blue;
    }

    &--in-progress {
        background-color: $color-info;
    }

    &--active {
        background-color: $color-success;
    }

    &--completed {
        background-color: $color-red;
    }

    &--finished {
        background-color: $color-success;
    }

    &--canceled {
        color: $color-dark-gray;
    }

    &--new {
        background-color: $color-danger-3;
    }
}
