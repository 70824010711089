/* stylelint-disable-next-line scss/at-rule-no-unknown */
@custom-media --tablet (width >= 768px);
@custom-media --desktop (width >= 1024px);
@custom-media --desktop-wide (width >= 1600px);
@custom-media --only-mobile (width < 768px);
@custom-media --only-tablet (width >= 768px) and (width < 1024px);
@custom-media --only-desktop (width >= 1024px) and (width < 1600px);
@custom-media --until-desktop (width < 1024px);
/* general mixins and helpers */
/* Typography */
@font-face {
  font-family: Circe;
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/Circe-ExtraBold.woff2") format("woff2"), url("../fonts/Circe-ExtraBold.woff") format("woff"); }

@font-face {
  font-family: Circe;
  font-display: swap;
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/Circe-Thin.woff2") format("woff2"), url("../fonts/Circe-Thin.woff") format("woff"); }

@font-face {
  font-family: Circe;
  font-display: swap;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Circe-Regular.woff2") format("woff2"), url("../fonts/Circe-Regular.woff") format("woff"); }

@font-face {
  font-family: Circe;
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Circe-Light.woff2") format("woff2"), url("../fonts/Circe-Light.woff") format("woff"); }

@font-face {
  font-family: Circe;
  font-display: swap;
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Circe-Bold.woff2") format("woff2"), url("../fonts/Circe-Bold.woff") format("woff"); }

@font-face {
  font-family: Circe;
  font-display: swap;
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/Circe-ExtraLight.woff2") format("woff2"), url("../fonts/Circe-ExtraLight.woff") format("woff"); }

/*  reset default styles */
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p {
  margin: 0; }

a:not([class]) {
  color: #f9150a;
  font-size: 14px;
  text-decoration: underline;
  transition: color 0.3s cubic-bezier(0.4, 0.47, 0.17, 0.98); }
  .can-hover a:not([class]):hover {
    text-decoration: none; }
  a:not([class]):disabled, a:not([class]).is-disabled {
    color: #b1b1b1; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

img {
  vertical-align: top; }

.title-type-1 {
  font-size: 36px;
  font-weight: normal;
  letter-spacing: -0.002em;
  line-height: 1.6; }

.title-type-2 {
  font-size: 30px;
  font-weight: normal;
  line-height: 1.6; }

.title-type-3 {
  font-size: 25px;
  font-weight: normal;
  line-height: 1.6; }

.title-type-4 {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.3; }

.title-type-5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38; }
  @media (--tablet) {
    .title-type-5 {
      font-size: 18px;
      line-height: 1.33; } }

.title-type-6 {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 1.67; }

/* general grid styles */
*,
*::before,
*::after {
  box-sizing: border-box; }

[data-whatintent='mouse'] *:focus,
[data-whatintent='touch'] *:focus {
  outline: none; }

.html {
  position: relative;
  overflow-y: scroll;
  height: 100%;
  font-size: 100%; }
  .html.is-blocked {
    overflow: hidden; }
  .html.is-blocked-touch {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
    height: auto; }

.body {
  height: 100%;
  margin: 0;
  color: #000000;
  font-family: Circe, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth; }

.container {
  max-width: 1350px;
  margin-right: auto;
  margin-left: auto; }

.container-tiny {
  max-width: 430px;
  margin-right: auto;
  margin-left: auto; }

.container-small {
  max-width: 765px;
  margin-right: auto;
  margin-left: auto; }

.container-medium {
  max-width: 964px;
  margin-right: auto;
  margin-left: auto; }

.main {
  display: flex; }
  .main__sidebar {
    position: sticky;
    top: 0;
    flex-shrink: 0;
    width: 250px;
    height: 100vh; }
  .main__sidebar-logo {
    padding: 15px 20px;
    border-bottom: 1px solid #f7f7f7; }
    .main__sidebar-logo img {
      width: 140px;
      height: 50px; }
  .main__content {
    width: 100%; }
  .main__content-inner {
    overflow: hidden;
    min-height: calc(100vh - 80px - 75px);
    padding: 50px 30px 80px 30px;
    background-color: #f7f7f7; }
  .main__content-logo {
    display: block;
    margin-right: auto;
    margin-left: auto; }
  .main--login .main__content-inner {
    min-height: calc(100vh - 75px);
    padding-top: 30px; }

.hr::before {
  content: '';
  display: block;
  border-top: 1px solid #b1b1b1; }

.table-wrapper {
  position: relative;
  overflow-x: auto;
  min-height: 100px;
  white-space: nowrap; }

.table {
  width: 100%;
  min-width: 100%;
  border-collapse: collapse; }
  .table th,
  .table td {
    padding: 15px 30px;
    font-size: 18px;
    text-align: left; }
  .table thead tr {
    background-color: #ffffff; }
  .table thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #ffffff; }
  .table thead th,
  .table thead td {
    font-size: 14px;
    font-weight: 700; }
  .table tbody td:first-of-type {
    font-weight: 700; }
  .table tbody tr {
    background-color: #f7f7f7;
    transition: background-color 0.3s linear; }
    .can-hover .table tbody tr:hover,
    [data-whatintent='keyboard'] .table tbody tr:focus {
      background-color: #f2f2f2; }
  .table--type-2 thead th,
  .table--type-2 thead td {
    padding: 15px; }
  .table--type-2 thead tr,
  .table--type-2 thead th {
    background-color: transparent; }
  .table--type-2 thead tr {
    border-bottom: 1px solid #b1b1b1; }
  .table--type-2 th,
  .table--type-2 td {
    padding: 20px 15px; }
  .table--type-2 a:not([class]),
  .table--type-2 button:not([class]) {
    color: inherit;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.05em; }
    .can-hover .table--type-2 a:not([class]):hover,
    [data-whatintent='keyboard'] .table--type-2 a:not([class]):focus, .can-hover
    .table--type-2 button:not([class]):hover,
    [data-whatintent='keyboard']
    .table--type-2 button:not([class]):focus {
      color: #ee1308;
      text-decoration: underline; }
  .table--type-2 tbody tr {
    border-top: 1px solid #e0e0e0;
    transition: border-color 0.3s linear; }
    .can-hover .table--type-2 tbody tr:hover,
    [data-whatintent='keyboard'] .table--type-2 tbody tr:focus {
      border-top-color: transparent; }
      .can-hover .table--type-2 tbody tr:hover + tr,
      [data-whatintent='keyboard'] .table--type-2 tbody tr:focus + tr {
        border-top-color: transparent; }
  .table--type-2 tbody td:last-of-type {
    width: 40%; }
  .table--orders tbody td:last-of-type {
    width: auto;
    text-align: right; }

.btn,
.btn-default,
.btn-primary,
.btn-secondary,
.btn-tertiary,
.btn-arrow,
.btn-circle,
.btn-square {
  padding: 0;
  background-color: transparent;
  border: none;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center; }
  .can-hover .btn:hover:not(:disabled), .can-hover .btn:hover:not(.is-disabled), .can-hover
  .btn-default:hover:not(:disabled), .can-hover
  .btn-default:hover:not(.is-disabled), .can-hover
  .btn-primary:hover:not(:disabled), .can-hover
  .btn-primary:hover:not(.is-disabled), .can-hover
  .btn-secondary:hover:not(:disabled), .can-hover
  .btn-secondary:hover:not(.is-disabled), .can-hover
  .btn-tertiary:hover:not(:disabled), .can-hover
  .btn-tertiary:hover:not(.is-disabled), .can-hover
  .btn-arrow:hover:not(:disabled), .can-hover
  .btn-arrow:hover:not(.is-disabled), .can-hover
  .btn-circle:hover:not(:disabled), .can-hover
  .btn-circle:hover:not(.is-disabled), .can-hover
  .btn-square:hover:not(:disabled), .can-hover
  .btn-square:hover:not(.is-disabled) {
    cursor: pointer; }
  .btn .icon,
  .btn-default .icon,
  .btn-primary .icon,
  .btn-secondary .icon,
  .btn-tertiary .icon,
  .btn-arrow .icon,
  .btn-circle .icon,
  .btn-square .icon {
    flex-shrink: 0; }
  .btn:disabled, .btn.is-disabled,
  .btn-default:disabled,
  .btn-default.is-disabled,
  .btn-primary:disabled,
  .btn-primary.is-disabled,
  .btn-secondary:disabled,
  .btn-secondary.is-disabled,
  .btn-tertiary:disabled,
  .btn-tertiary.is-disabled,
  .btn-arrow:disabled,
  .btn-arrow.is-disabled,
  .btn-circle:disabled,
  .btn-circle.is-disabled,
  .btn-square:disabled,
  .btn-square.is-disabled {
    cursor: not-allowed; }

.btn-primary {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1;
  text-transform: uppercase;
  text-decoration: none;
  min-width: 200px;
  padding: 16px 15px;
  border-radius: 5px;
  transition: background-color 0.3s linear; }
  .btn-primary:disabled, .btn-primary.is-disabled {
    background-color: #f7f7f7;
    color: #b1b1b1; }
    .can-hover .btn-primary:disabled:hover,
    [data-whatintent='keyboard'] .btn-primary:disabled:focus, .can-hover .btn-primary.is-disabled:hover,
    [data-whatintent='keyboard'] .btn-primary.is-disabled:focus {
      background-color: #f7f7f7;
      color: #b1b1b1; }
  .btn-primary--red {
    background-color: #f9150a;
    color: #ffffff; }
    .can-hover .btn-primary--red:hover,
    [data-whatintent='keyboard'] .btn-primary--red:focus {
      background-color: #ee1308; }
  .btn-primary--with-icon > :first-child {
    margin-right: 10px; }
  .btn-primary--with-icon > :last-child {
    margin-left: 10px; }

.btn-secondary {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1;
  text-transform: uppercase;
  text-decoration: none;
  min-width: 170px;
  padding: 14px 20px 12px 20px;
  border-radius: 30px;
  font-weight: normal;
  transition: background-color 0.3s linear; }
  .btn-secondary:disabled, .btn-secondary.is-disabled {
    background-color: #f7f7f7;
    color: #b1b1b1; }
    .can-hover .btn-secondary:disabled:hover,
    [data-whatintent='keyboard'] .btn-secondary:disabled:focus, .can-hover .btn-secondary.is-disabled:hover,
    [data-whatintent='keyboard'] .btn-secondary.is-disabled:focus {
      background-color: #f7f7f7;
      color: #b1b1b1; }
  .btn-secondary--red {
    background-color: #f9150a;
    color: #ffffff; }
    .can-hover .btn-secondary--red:hover,
    [data-whatintent='keyboard'] .btn-secondary--red:focus {
      background-color: #ee1308; }
  .btn-secondary--green {
    background-color: #82c10d;
    color: #ffffff; }
    .can-hover .btn-secondary--green:hover,
    [data-whatintent='keyboard'] .btn-secondary--green:focus {
      background-color: #72a90b; }
  .btn-secondary--blue {
    background-color: #6cbefd;
    color: #ffffff; }
    .can-hover .btn-secondary--blue:hover,
    [data-whatintent='keyboard'] .btn-secondary--blue:focus {
      background-color: #53b3fd; }
  .btn-secondary--white {
    background-color: #ffffff;
    color: #ff8d6f; }
    .can-hover .btn-secondary--white:hover,
    [data-whatintent='keyboard'] .btn-secondary--white:focus {
      background-color: #fafafa; }
  .btn-secondary--small {
    min-width: 128px;
    padding: 10px; }
  .btn-secondary--with-icon > :last-child {
    margin-left: 10px; }

.btn-tertiary {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1;
  text-transform: uppercase;
  text-decoration: none;
  padding: 7px 15px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: normal;
  text-transform: initial;
  transition: background-color 0.3s linear; }
  .btn-tertiary:disabled, .btn-tertiary.is-disabled {
    background-color: #f7f7f7;
    color: #b1b1b1; }
    .can-hover .btn-tertiary:disabled:hover,
    [data-whatintent='keyboard'] .btn-tertiary:disabled:focus, .can-hover .btn-tertiary.is-disabled:hover,
    [data-whatintent='keyboard'] .btn-tertiary.is-disabled:focus {
      background-color: #f7f7f7;
      color: #b1b1b1; }
  .btn-tertiary--green {
    background-color: #82c10d;
    color: #ffffff; }
    .can-hover .btn-tertiary--green:hover,
    [data-whatintent='keyboard'] .btn-tertiary--green:focus {
      background-color: #72a90b; }
  .btn-tertiary--blue {
    background-color: #6cbefd;
    color: #ffffff; }
    .can-hover .btn-tertiary--blue:hover,
    [data-whatintent='keyboard'] .btn-tertiary--blue:focus {
      background-color: #53b3fd; }
  .btn-tertiary--color-red {
    color: #f9150a; }
  .btn-tertiary--with-icon > :last-child {
    margin-left: 10px; }

.btn-circle {
  overflow: hidden;
  width: 32px;
  height: 32px;
  background-color: #f7f7f7;
  border-radius: 50%; }

.btn-square {
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 5px;
  transition: box-shadow 0.3s linear; }
  .btn-square + .btn-square {
    margin-left: 20px; }
  .can-hover .btn-square:hover,
  [data-whatintent='keyboard'] .btn-square:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25); }
  .btn-square--admit .icon {
    color: #82c10d; }
  .btn-square--cancel .icon {
    color: #f9150a; }
  .btn-square--add .icon {
    color: #6cbefd; }

.btn-default--with-icon > :first-child {
  margin-right: 3px; }

.btn-default--color-red {
  color: #f9150a;
  text-decoration: underline; }

/* global styles for SVG */
.icon {
  flex-shrink: 0;
  vertical-align: top;
  fill: currentColor; }
  .icon path {
    fill: inherit; }

.responsive-media {
  position: relative; }
  .responsive-media > img,
  .responsive-media > video {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%; }

img,
video {
  max-width: 100%; }

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap; }

.hidden {
  display: none; }

.show-if-tablet {
  display: none; }
  @media (--tablet) {
    .show-if-tablet {
      display: block; } }

.show-if-desktop {
  display: none; }
  @media (--desktop) {
    .show-if-desktop {
      display: block; } }

.show-if-desktop-wide {
  display: none; }
  @media (--desktop-wide) {
    .show-if-desktop-wide {
      display: block; } }

@media (--tablet) {
  .hide-if-tablet {
    display: none; } }

@media (--desktop) {
  .hide-if-desktop {
    display: none; } }

@media (--desktop-wide) {
  .hide-if-desktop-wide {
    display: none; } }

.text-align-center {
  text-align: center; }

.initial-p-0 {
  padding-top: 0rem;
  padding-bottom: 0rem; }

.initial-p-5 {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem; }

.initial-p-10 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }

.initial-p-15 {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem; }

.initial-p-20 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.initial-p-25 {
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem; }

.initial-p-30 {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }

.initial-p-40 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.initial-p-50 {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem; }

.initial-p-60 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; }

.initial-p-80 {
  padding-top: 5rem;
  padding-bottom: 5rem; }

.initial-p-100 {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem; }

.initial-p-120 {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem; }

.initial-p-280 {
  padding-top: 17.5rem;
  padding-bottom: 17.5rem; }

.initial-pt-0 {
  padding-top: 0rem; }

.initial-pt-5 {
  padding-top: 0.3125rem; }

.initial-pt-10 {
  padding-top: 0.625rem; }

.initial-pt-15 {
  padding-top: 0.9375rem; }

.initial-pt-20 {
  padding-top: 1.25rem; }

.initial-pt-25 {
  padding-top: 1.5625rem; }

.initial-pt-30 {
  padding-top: 1.875rem; }

.initial-pt-40 {
  padding-top: 2.5rem; }

.initial-pt-50 {
  padding-top: 3.125rem; }

.initial-pt-60 {
  padding-top: 3.75rem; }

.initial-pt-80 {
  padding-top: 5rem; }

.initial-pt-100 {
  padding-top: 6.25rem; }

.initial-pt-120 {
  padding-top: 7.5rem; }

.initial-pt-280 {
  padding-top: 17.5rem; }

.initial-pb-0 {
  padding-bottom: 0rem; }

.initial-pb-5 {
  padding-bottom: 0.3125rem; }

.initial-pb-10 {
  padding-bottom: 0.625rem; }

.initial-pb-15 {
  padding-bottom: 0.9375rem; }

.initial-pb-20 {
  padding-bottom: 1.25rem; }

.initial-pb-25 {
  padding-bottom: 1.5625rem; }

.initial-pb-30 {
  padding-bottom: 1.875rem; }

.initial-pb-40 {
  padding-bottom: 2.5rem; }

.initial-pb-50 {
  padding-bottom: 3.125rem; }

.initial-pb-60 {
  padding-bottom: 3.75rem; }

.initial-pb-80 {
  padding-bottom: 5rem; }

.initial-pb-100 {
  padding-bottom: 6.25rem; }

.initial-pb-120 {
  padding-bottom: 7.5rem; }

.initial-pb-280 {
  padding-bottom: 17.5rem; }

.initial-m-0 {
  margin-top: 0rem;
  margin-bottom: 0rem; }

.initial-m-5 {
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem; }

.initial-m-10 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem; }

.initial-m-15 {
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem; }

.initial-m-20 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

.initial-m-25 {
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem; }

.initial-m-30 {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem; }

.initial-m-40 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.initial-m-50 {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem; }

.initial-m-60 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem; }

.initial-m-80 {
  margin-top: 5rem;
  margin-bottom: 5rem; }

.initial-m-100 {
  margin-top: 6.25rem;
  margin-bottom: 6.25rem; }

.initial-m-120 {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem; }

.initial-m-280 {
  margin-top: 17.5rem;
  margin-bottom: 17.5rem; }

.initial-mt-0 {
  margin-top: 0rem; }

.initial-mt-5 {
  margin-top: 0.3125rem; }

.initial-mt-10 {
  margin-top: 0.625rem; }

.initial-mt-15 {
  margin-top: 0.9375rem; }

.initial-mt-20 {
  margin-top: 1.25rem; }

.initial-mt-25 {
  margin-top: 1.5625rem; }

.initial-mt-30 {
  margin-top: 1.875rem; }

.initial-mt-40 {
  margin-top: 2.5rem; }

.initial-mt-50 {
  margin-top: 3.125rem; }

.initial-mt-60 {
  margin-top: 3.75rem; }

.initial-mt-80 {
  margin-top: 5rem; }

.initial-mt-100 {
  margin-top: 6.25rem; }

.initial-mt-120 {
  margin-top: 7.5rem; }

.initial-mt-280 {
  margin-top: 17.5rem; }

.initial-mb-0 {
  margin-bottom: 0rem; }

.initial-mb-5 {
  margin-bottom: 0.3125rem; }

.initial-mb-10 {
  margin-bottom: 0.625rem; }

.initial-mb-15 {
  margin-bottom: 0.9375rem; }

.initial-mb-20 {
  margin-bottom: 1.25rem; }

.initial-mb-25 {
  margin-bottom: 1.5625rem; }

.initial-mb-30 {
  margin-bottom: 1.875rem; }

.initial-mb-40 {
  margin-bottom: 2.5rem; }

.initial-mb-50 {
  margin-bottom: 3.125rem; }

.initial-mb-60 {
  margin-bottom: 3.75rem; }

.initial-mb-80 {
  margin-bottom: 5rem; }

.initial-mb-100 {
  margin-bottom: 6.25rem; }

.initial-mb-120 {
  margin-bottom: 7.5rem; }

.initial-mb-280 {
  margin-bottom: 17.5rem; }

@media (--tablet) {
  .tablet-p-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .tablet-p-5 {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem; }
  .tablet-p-10 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .tablet-p-15 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem; }
  .tablet-p-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .tablet-p-25 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem; }
  .tablet-p-30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem; }
  .tablet-p-40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .tablet-p-50 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
  .tablet-p-60 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .tablet-p-80 {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .tablet-p-100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem; }
  .tablet-p-120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem; }
  .tablet-p-280 {
    padding-top: 17.5rem;
    padding-bottom: 17.5rem; }
  .tablet-pt-0 {
    padding-top: 0rem; }
  .tablet-pt-5 {
    padding-top: 0.3125rem; }
  .tablet-pt-10 {
    padding-top: 0.625rem; }
  .tablet-pt-15 {
    padding-top: 0.9375rem; }
  .tablet-pt-20 {
    padding-top: 1.25rem; }
  .tablet-pt-25 {
    padding-top: 1.5625rem; }
  .tablet-pt-30 {
    padding-top: 1.875rem; }
  .tablet-pt-40 {
    padding-top: 2.5rem; }
  .tablet-pt-50 {
    padding-top: 3.125rem; }
  .tablet-pt-60 {
    padding-top: 3.75rem; }
  .tablet-pt-80 {
    padding-top: 5rem; }
  .tablet-pt-100 {
    padding-top: 6.25rem; }
  .tablet-pt-120 {
    padding-top: 7.5rem; }
  .tablet-pt-280 {
    padding-top: 17.5rem; }
  .tablet-pb-0 {
    padding-bottom: 0rem; }
  .tablet-pb-5 {
    padding-bottom: 0.3125rem; }
  .tablet-pb-10 {
    padding-bottom: 0.625rem; }
  .tablet-pb-15 {
    padding-bottom: 0.9375rem; }
  .tablet-pb-20 {
    padding-bottom: 1.25rem; }
  .tablet-pb-25 {
    padding-bottom: 1.5625rem; }
  .tablet-pb-30 {
    padding-bottom: 1.875rem; }
  .tablet-pb-40 {
    padding-bottom: 2.5rem; }
  .tablet-pb-50 {
    padding-bottom: 3.125rem; }
  .tablet-pb-60 {
    padding-bottom: 3.75rem; }
  .tablet-pb-80 {
    padding-bottom: 5rem; }
  .tablet-pb-100 {
    padding-bottom: 6.25rem; }
  .tablet-pb-120 {
    padding-bottom: 7.5rem; }
  .tablet-pb-280 {
    padding-bottom: 17.5rem; }
  .tablet-m-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .tablet-m-5 {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem; }
  .tablet-m-10 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .tablet-m-15 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem; }
  .tablet-m-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .tablet-m-25 {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem; }
  .tablet-m-30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem; }
  .tablet-m-40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .tablet-m-50 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem; }
  .tablet-m-60 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .tablet-m-80 {
    margin-top: 5rem;
    margin-bottom: 5rem; }
  .tablet-m-100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem; }
  .tablet-m-120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem; }
  .tablet-m-280 {
    margin-top: 17.5rem;
    margin-bottom: 17.5rem; }
  .tablet-mt-0 {
    margin-top: 0rem; }
  .tablet-mt-5 {
    margin-top: 0.3125rem; }
  .tablet-mt-10 {
    margin-top: 0.625rem; }
  .tablet-mt-15 {
    margin-top: 0.9375rem; }
  .tablet-mt-20 {
    margin-top: 1.25rem; }
  .tablet-mt-25 {
    margin-top: 1.5625rem; }
  .tablet-mt-30 {
    margin-top: 1.875rem; }
  .tablet-mt-40 {
    margin-top: 2.5rem; }
  .tablet-mt-50 {
    margin-top: 3.125rem; }
  .tablet-mt-60 {
    margin-top: 3.75rem; }
  .tablet-mt-80 {
    margin-top: 5rem; }
  .tablet-mt-100 {
    margin-top: 6.25rem; }
  .tablet-mt-120 {
    margin-top: 7.5rem; }
  .tablet-mt-280 {
    margin-top: 17.5rem; }
  .tablet-mb-0 {
    margin-bottom: 0rem; }
  .tablet-mb-5 {
    margin-bottom: 0.3125rem; }
  .tablet-mb-10 {
    margin-bottom: 0.625rem; }
  .tablet-mb-15 {
    margin-bottom: 0.9375rem; }
  .tablet-mb-20 {
    margin-bottom: 1.25rem; }
  .tablet-mb-25 {
    margin-bottom: 1.5625rem; }
  .tablet-mb-30 {
    margin-bottom: 1.875rem; }
  .tablet-mb-40 {
    margin-bottom: 2.5rem; }
  .tablet-mb-50 {
    margin-bottom: 3.125rem; }
  .tablet-mb-60 {
    margin-bottom: 3.75rem; }
  .tablet-mb-80 {
    margin-bottom: 5rem; }
  .tablet-mb-100 {
    margin-bottom: 6.25rem; }
  .tablet-mb-120 {
    margin-bottom: 7.5rem; }
  .tablet-mb-280 {
    margin-bottom: 17.5rem; } }

@media (--desktop) {
  .desktop-p-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .desktop-p-5 {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem; }
  .desktop-p-10 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .desktop-p-15 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem; }
  .desktop-p-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .desktop-p-25 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem; }
  .desktop-p-30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem; }
  .desktop-p-40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .desktop-p-50 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
  .desktop-p-60 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .desktop-p-80 {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .desktop-p-100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem; }
  .desktop-p-120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem; }
  .desktop-p-280 {
    padding-top: 17.5rem;
    padding-bottom: 17.5rem; }
  .desktop-pt-0 {
    padding-top: 0rem; }
  .desktop-pt-5 {
    padding-top: 0.3125rem; }
  .desktop-pt-10 {
    padding-top: 0.625rem; }
  .desktop-pt-15 {
    padding-top: 0.9375rem; }
  .desktop-pt-20 {
    padding-top: 1.25rem; }
  .desktop-pt-25 {
    padding-top: 1.5625rem; }
  .desktop-pt-30 {
    padding-top: 1.875rem; }
  .desktop-pt-40 {
    padding-top: 2.5rem; }
  .desktop-pt-50 {
    padding-top: 3.125rem; }
  .desktop-pt-60 {
    padding-top: 3.75rem; }
  .desktop-pt-80 {
    padding-top: 5rem; }
  .desktop-pt-100 {
    padding-top: 6.25rem; }
  .desktop-pt-120 {
    padding-top: 7.5rem; }
  .desktop-pt-280 {
    padding-top: 17.5rem; }
  .desktop-pb-0 {
    padding-bottom: 0rem; }
  .desktop-pb-5 {
    padding-bottom: 0.3125rem; }
  .desktop-pb-10 {
    padding-bottom: 0.625rem; }
  .desktop-pb-15 {
    padding-bottom: 0.9375rem; }
  .desktop-pb-20 {
    padding-bottom: 1.25rem; }
  .desktop-pb-25 {
    padding-bottom: 1.5625rem; }
  .desktop-pb-30 {
    padding-bottom: 1.875rem; }
  .desktop-pb-40 {
    padding-bottom: 2.5rem; }
  .desktop-pb-50 {
    padding-bottom: 3.125rem; }
  .desktop-pb-60 {
    padding-bottom: 3.75rem; }
  .desktop-pb-80 {
    padding-bottom: 5rem; }
  .desktop-pb-100 {
    padding-bottom: 6.25rem; }
  .desktop-pb-120 {
    padding-bottom: 7.5rem; }
  .desktop-pb-280 {
    padding-bottom: 17.5rem; }
  .desktop-m-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .desktop-m-5 {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem; }
  .desktop-m-10 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .desktop-m-15 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem; }
  .desktop-m-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .desktop-m-25 {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem; }
  .desktop-m-30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem; }
  .desktop-m-40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .desktop-m-50 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem; }
  .desktop-m-60 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .desktop-m-80 {
    margin-top: 5rem;
    margin-bottom: 5rem; }
  .desktop-m-100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem; }
  .desktop-m-120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem; }
  .desktop-m-280 {
    margin-top: 17.5rem;
    margin-bottom: 17.5rem; }
  .desktop-mt-0 {
    margin-top: 0rem; }
  .desktop-mt-5 {
    margin-top: 0.3125rem; }
  .desktop-mt-10 {
    margin-top: 0.625rem; }
  .desktop-mt-15 {
    margin-top: 0.9375rem; }
  .desktop-mt-20 {
    margin-top: 1.25rem; }
  .desktop-mt-25 {
    margin-top: 1.5625rem; }
  .desktop-mt-30 {
    margin-top: 1.875rem; }
  .desktop-mt-40 {
    margin-top: 2.5rem; }
  .desktop-mt-50 {
    margin-top: 3.125rem; }
  .desktop-mt-60 {
    margin-top: 3.75rem; }
  .desktop-mt-80 {
    margin-top: 5rem; }
  .desktop-mt-100 {
    margin-top: 6.25rem; }
  .desktop-mt-120 {
    margin-top: 7.5rem; }
  .desktop-mt-280 {
    margin-top: 17.5rem; }
  .desktop-mb-0 {
    margin-bottom: 0rem; }
  .desktop-mb-5 {
    margin-bottom: 0.3125rem; }
  .desktop-mb-10 {
    margin-bottom: 0.625rem; }
  .desktop-mb-15 {
    margin-bottom: 0.9375rem; }
  .desktop-mb-20 {
    margin-bottom: 1.25rem; }
  .desktop-mb-25 {
    margin-bottom: 1.5625rem; }
  .desktop-mb-30 {
    margin-bottom: 1.875rem; }
  .desktop-mb-40 {
    margin-bottom: 2.5rem; }
  .desktop-mb-50 {
    margin-bottom: 3.125rem; }
  .desktop-mb-60 {
    margin-bottom: 3.75rem; }
  .desktop-mb-80 {
    margin-bottom: 5rem; }
  .desktop-mb-100 {
    margin-bottom: 6.25rem; }
  .desktop-mb-120 {
    margin-bottom: 7.5rem; }
  .desktop-mb-280 {
    margin-bottom: 17.5rem; } }

@media (--desktop-wide) {
  .desktop-wide-p-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .desktop-wide-p-5 {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem; }
  .desktop-wide-p-10 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .desktop-wide-p-15 {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem; }
  .desktop-wide-p-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .desktop-wide-p-25 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem; }
  .desktop-wide-p-30 {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem; }
  .desktop-wide-p-40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .desktop-wide-p-50 {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem; }
  .desktop-wide-p-60 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .desktop-wide-p-80 {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .desktop-wide-p-100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem; }
  .desktop-wide-p-120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem; }
  .desktop-wide-p-280 {
    padding-top: 17.5rem;
    padding-bottom: 17.5rem; }
  .desktop-wide-pt-0 {
    padding-top: 0rem; }
  .desktop-wide-pt-5 {
    padding-top: 0.3125rem; }
  .desktop-wide-pt-10 {
    padding-top: 0.625rem; }
  .desktop-wide-pt-15 {
    padding-top: 0.9375rem; }
  .desktop-wide-pt-20 {
    padding-top: 1.25rem; }
  .desktop-wide-pt-25 {
    padding-top: 1.5625rem; }
  .desktop-wide-pt-30 {
    padding-top: 1.875rem; }
  .desktop-wide-pt-40 {
    padding-top: 2.5rem; }
  .desktop-wide-pt-50 {
    padding-top: 3.125rem; }
  .desktop-wide-pt-60 {
    padding-top: 3.75rem; }
  .desktop-wide-pt-80 {
    padding-top: 5rem; }
  .desktop-wide-pt-100 {
    padding-top: 6.25rem; }
  .desktop-wide-pt-120 {
    padding-top: 7.5rem; }
  .desktop-wide-pt-280 {
    padding-top: 17.5rem; }
  .desktop-wide-pb-0 {
    padding-bottom: 0rem; }
  .desktop-wide-pb-5 {
    padding-bottom: 0.3125rem; }
  .desktop-wide-pb-10 {
    padding-bottom: 0.625rem; }
  .desktop-wide-pb-15 {
    padding-bottom: 0.9375rem; }
  .desktop-wide-pb-20 {
    padding-bottom: 1.25rem; }
  .desktop-wide-pb-25 {
    padding-bottom: 1.5625rem; }
  .desktop-wide-pb-30 {
    padding-bottom: 1.875rem; }
  .desktop-wide-pb-40 {
    padding-bottom: 2.5rem; }
  .desktop-wide-pb-50 {
    padding-bottom: 3.125rem; }
  .desktop-wide-pb-60 {
    padding-bottom: 3.75rem; }
  .desktop-wide-pb-80 {
    padding-bottom: 5rem; }
  .desktop-wide-pb-100 {
    padding-bottom: 6.25rem; }
  .desktop-wide-pb-120 {
    padding-bottom: 7.5rem; }
  .desktop-wide-pb-280 {
    padding-bottom: 17.5rem; }
  .desktop-wide-m-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .desktop-wide-m-5 {
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem; }
  .desktop-wide-m-10 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .desktop-wide-m-15 {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem; }
  .desktop-wide-m-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .desktop-wide-m-25 {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem; }
  .desktop-wide-m-30 {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem; }
  .desktop-wide-m-40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .desktop-wide-m-50 {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem; }
  .desktop-wide-m-60 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .desktop-wide-m-80 {
    margin-top: 5rem;
    margin-bottom: 5rem; }
  .desktop-wide-m-100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem; }
  .desktop-wide-m-120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem; }
  .desktop-wide-m-280 {
    margin-top: 17.5rem;
    margin-bottom: 17.5rem; }
  .desktop-wide-mt-0 {
    margin-top: 0rem; }
  .desktop-wide-mt-5 {
    margin-top: 0.3125rem; }
  .desktop-wide-mt-10 {
    margin-top: 0.625rem; }
  .desktop-wide-mt-15 {
    margin-top: 0.9375rem; }
  .desktop-wide-mt-20 {
    margin-top: 1.25rem; }
  .desktop-wide-mt-25 {
    margin-top: 1.5625rem; }
  .desktop-wide-mt-30 {
    margin-top: 1.875rem; }
  .desktop-wide-mt-40 {
    margin-top: 2.5rem; }
  .desktop-wide-mt-50 {
    margin-top: 3.125rem; }
  .desktop-wide-mt-60 {
    margin-top: 3.75rem; }
  .desktop-wide-mt-80 {
    margin-top: 5rem; }
  .desktop-wide-mt-100 {
    margin-top: 6.25rem; }
  .desktop-wide-mt-120 {
    margin-top: 7.5rem; }
  .desktop-wide-mt-280 {
    margin-top: 17.5rem; }
  .desktop-wide-mb-0 {
    margin-bottom: 0rem; }
  .desktop-wide-mb-5 {
    margin-bottom: 0.3125rem; }
  .desktop-wide-mb-10 {
    margin-bottom: 0.625rem; }
  .desktop-wide-mb-15 {
    margin-bottom: 0.9375rem; }
  .desktop-wide-mb-20 {
    margin-bottom: 1.25rem; }
  .desktop-wide-mb-25 {
    margin-bottom: 1.5625rem; }
  .desktop-wide-mb-30 {
    margin-bottom: 1.875rem; }
  .desktop-wide-mb-40 {
    margin-bottom: 2.5rem; }
  .desktop-wide-mb-50 {
    margin-bottom: 3.125rem; }
  .desktop-wide-mb-60 {
    margin-bottom: 3.75rem; }
  .desktop-wide-mb-80 {
    margin-bottom: 5rem; }
  .desktop-wide-mb-100 {
    margin-bottom: 6.25rem; }
  .desktop-wide-mb-120 {
    margin-bottom: 7.5rem; }
  .desktop-wide-mb-280 {
    margin-bottom: 17.5rem; } }
