/* Typography */

@mixin h1 {
    font-size: 36px;
    font-weight: normal;
    letter-spacing: -0.002em;
    line-height: 1.6;
}

@mixin h2 {
    font-size: 30px;
    font-weight: normal;
    line-height: 1.6;
}

@mixin h3 {
    font-size: 25px;
    font-weight: normal;
    line-height: 1.6;
}

@mixin h4 {
    font-size: 20px;
    font-weight: normal;
    line-height: 1.3;
}

@mixin h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.38;

    @include tablet {
        font-size: 18px;
        line-height: 1.33;
    }
}

@mixin h6 {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 1.67;
}

@mixin paragraph-intro {
    font-size: 16px;
    line-height: 1.5;
}

@mixin paragraph {
    font-size: 14px;
    line-height: 1.57;
}

@mixin button-link {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 1;
    text-transform: uppercase;
    text-decoration: none;
}

@mixin text-link-large {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;

    @include tablet {
        text-decoration: none;
    }
}

@mixin text-link-small {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;

    @include tablet {
        line-height: 1.28;
        text-decoration: none;
    }
}
