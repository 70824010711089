.btn,
.btn-default,
.btn-primary,
.btn-secondary,
.btn-tertiary,
.btn-arrow,
.btn-circle,
.btn-square {
    @include reset-button();

    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    @include hover {
        &:not(:disabled),
        &:not(.is-disabled) {
            cursor: pointer;
        }
    }

    .icon {
        flex-shrink: 0;
    }

    &:disabled,
    &.is-disabled {
        cursor: not-allowed;
    }
}

.btn-primary {
    @include button-link();

    min-width: 200px;
    padding: 16px 15px;
    border-radius: 5px;
    transition: background-color $transition-default;

    &:disabled,
    &.is-disabled {
        background-color: $color-grey;
        color: $color-light-grey;

        @include hover-focus {
            background-color: $color-grey;
            color: $color-light-grey;
        }
    }

    &--red {
        background-color: $color-red;
        color: $color-white;

        @include hover-focus {
            background-color: $color-red-hover;
        }
    }

    &--with-icon {
        & > :first-child {
            margin-right: 10px;
        }

        & > :last-child {
            margin-left: 10px;
        }
    }
}

.btn-secondary {
    @include button-link();

    min-width: 170px;
    padding: 14px 20px 12px 20px;
    border-radius: 30px;
    font-weight: normal;
    transition: background-color $transition-default;

    &:disabled,
    &.is-disabled {
        background-color: $color-grey;
        color: $color-light-grey;

        @include hover-focus {
            background-color: $color-grey;
            color: $color-light-grey;
        }
    }

    &--red {
        background-color: $color-red;
        color: $color-white;

        @include hover-focus {
            background-color: $color-red-hover;
        }
    }

    &--green {
        background-color: $color-success;
        color: $color-white;

        @include hover-focus {
            background-color: darken($color-success, 5%);
        }
    }

    &--blue {
        background-color: $color-info;
        color: $color-white;

        @include hover-focus {
            background-color: darken($color-info, 5%);
        }
    }

    &--white {
        background-color: $color-white;
        color: $color-danger-2;

        @include hover-focus {
            background-color: $color-grey-2;
        }
    }

    &--small {
        min-width: 128px;
        padding: 10px;
    }

    &--with-icon {
        & > :last-child {
            margin-left: 10px;
        }
    }
}

.btn-tertiary {
    @include button-link();

    padding: 7px 15px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: normal;
    text-transform: initial;
    transition: background-color $transition-default;

    &:disabled,
    &.is-disabled {
        background-color: $color-grey;
        color: $color-light-grey;

        @include hover-focus {
            background-color: $color-grey;
            color: $color-light-grey;
        }
    }

    &--green {
        background-color: $color-success;
        color: $color-white;

        @include hover-focus {
            background-color: darken($color-success, 5%);
        }
    }

    &--blue {
        background-color: $color-info;
        color: $color-white;

        @include hover-focus {
            background-color: darken($color-info, 5%);
        }
    }

    &--color-red {
        color: $color-red;
    }

    &--with-icon {
        & > :last-child {
            margin-left: 10px;
        }
    }
}

.btn-circle {
    overflow: hidden;
    width: 32px;
    height: 32px;
    background-color: $color-grey;
    border-radius: 50%;
}

.btn-square {
    width: 32px;
    height: 32px;
    background-color: $color-white;
    border-radius: 5px;
    transition: box-shadow $transition-default;

    & + & {
        margin-left: 20px;
    }

    @include hover-focus {
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    }

    &--admit {
        .icon {
            color: $color-success;
        }
    }

    &--cancel {
        .icon {
            color: $color-red;
        }
    }

    &--add {
        .icon {
            color: $color-info;
        }
    }
}

.btn-default {
    &--with-icon {
        & > :first-child {
            margin-right: 3px;
        }
    }

    &--color-red {
        color: $color-red;
        text-decoration: underline;
    }
}
