@font-face {
    font-family: Circe;
    font-display: swap;
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/Circe-ExtraBold.woff2') format('woff2'),
        url('../fonts/Circe-ExtraBold.woff') format('woff');
}

@font-face {
    font-family: Circe;
    font-display: swap;
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/Circe-Thin.woff2') format('woff2'),
        url('../fonts/Circe-Thin.woff') format('woff');
}

@font-face {
    font-family: Circe;
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/Circe-Regular.woff2') format('woff2'),
        url('../fonts/Circe-Regular.woff') format('woff');
}

@font-face {
    font-family: Circe;
    font-display: swap;
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Circe-Light.woff2') format('woff2'),
        url('../fonts/Circe-Light.woff') format('woff');
}

@font-face {
    font-family: Circe;
    font-display: swap;
    font-style: normal;
    font-weight: bold;
    src: url('../fonts/Circe-Bold.woff2') format('woff2'),
        url('../fonts/Circe-Bold.woff') format('woff');
}

@font-face {
    font-family: Circe;
    font-display: swap;
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/Circe-ExtraLight.woff2') format('woff2'),
        url('../fonts/Circe-ExtraLight.woff') format('woff');
}
