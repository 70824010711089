.spinner {
    @keyframes spin {
        from {
            transform: translate(-50%, -50%) rotate(0deg);
        }

        to {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }

    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    opacity: 0.5;
    z-index: $layer-element-spinner;
    transform-origin: center center;
    animation: spin 0.5s linear normal infinite;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj4gICAgPHBhdGggb3BhY2l0eT0iMC44IiBkPSJNNTAsOTVDMjUuMiw5NSw1LDc0LjgsNSw1MGMwLTQuNywwLjctOS40LDIuMi0xMy44bDE3LjEsNS41QzIzLjQsNDQuNCwyMyw0Ny4yLDIzLDUwYzAsMTQuOSwxMi4xLDI3LDI3LDI3ICBzMjctMTIuMSwyNy0yN2MwLTE0LjktMTIuMS0yNy0yNy0yN2wtMC4xLDBWNXY5bDAtOUM3NC44LDUsOTUsMjUuMiw5NSw1MFM3NC44LDk1LDUwLDk1eiIvPjwvc3ZnPg==);

    &--small {
        width: 20px;
        height: 20px;
    }

    &.is-visible {
        display: block;
    }
}
