.header {
    $this: &;

    position: sticky;
    top: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    background-color: $color-white;

    &__search {
        flex-basis: 40%;
        margin-right: 30px;
    }

    &__logo {
        flex-shrink: 0;
        width: 250px;
    }

    &__panel {
        width: 100%;
        padding: 17px 30px;
    }

    &__order-btn {
        margin-right: auto;
        margin-left: auto;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__user-panel {
        display: flex;
        align-items: center;
        margin-left: 30px;

        > * + * {
            margin-left: 30px;
        }
    }
}
