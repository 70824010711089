.pagination {
    $this: &;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__item {
        & + & {
            margin-left: 15px;
        }
    }

    &__link {
        color: $color-dark-gray;
        font-size: 18px;
        font-weight: 700;
        text-decoration: none;
        transition: color $transition-default;

        &.is-active {
            color: $color-red;
        }
    }
}
