.form-checkbox {
    $this: &;

    &__label {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__tick {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        width: 14px;
        height: 14px;
        background-color: $color-white;
        border: 2px solid $color-dark-gray;
        border-radius: 2px;
        cursor: pointer;
        transition: background-color $transition-default, border-color $transition-default;

        svg {
            display: none;
            width: 10px;
            height: 10px;
        }
    }

    &__text {
        margin-left: 8px;
        cursor: pointer;

        @include tablet {
            font-size: 14px;
        }
    }

    &__control {
        @include visually-hidden();

        &:checked {
            & ~ #{$this}__label {
                #{$this}__tick {
                    background-color: $color-dark-gray;

                    svg {
                        display: block;
                        fill: $color-white;
                    }
                }
            }
        }

        &:disabled,
        &.is-disabled {
            background-color: $color-lightest-grey;
            border-color: $color-lightest-grey;

            &:checked {
                & ~ #{$this}__label {
                    #{$this}__tick {
                        color: $color-light-grey;
                    }
                }
            }
        }

        @include hover-focus {
            &:checked {
                & ~ #{$this}__label {
                    #{$this}__tick {
                        background-color: $color-grey-3;
                        border-color: $color-grey-3;
                    }
                }
            }
        }
    }

    & + & {
        margin-top: 20px;
    }

    &--align-start {
        #{$this}__label {
            align-items: flex-start;
        }

        #{$this}__text {
            margin-top: -4px;
            font-size: 14px;
            line-height: 1.64;
        }
    }
}
