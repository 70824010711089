.form-radio {
    $this: &;

    &__label {
        position: relative;
        display: flex;
        align-items: center;
    }

    &__tick {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        width: 14px;
        height: 14px;
        background-color: $color-white;
        border: 2px solid $color-dark-gray;
        border-radius: 50%;
        cursor: pointer;
        transition: border $transition-default;

        svg {
            display: none;
            width: 12px;
            height: 12px;
        }
    }

    &__text {
        margin-left: 8px;
        cursor: pointer;

        @include tablet {
            font-size: 14px;
        }
    }

    &__control {
        @include visually-hidden();

        &:checked {
            & ~ #{$this}__label {
                #{$this}__tick {
                    border-width: 5px;
                }
            }
        }
    }

    & + & {
        margin-top: 20px;
    }
}
