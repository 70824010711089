$color-white: #ffffff;
$color-black: #000000;
$color-dark-gray: #2a2a2a;
$color-grey: #f7f7f7;
$color-red: #f9150a;
$color-red-hover: #ee1308;
$color-danger: #ff8d6f;
$color-danger-2: #ff8d6f;
$color-danger-3: #FAA307;
$color-warning: #ffc832;
$color-info: #6cbefd;
$color-success: #82c10d;
$color-light-grey: #b1b1b1;
$color-lightest-grey: #e5e5e5;
$color-grey-2: #fafafa;
$color-grey-3: #525252;
$color-grey-4: #f2f2f2;
$color-grey-5: #e0e0e0;
$color-blue: #48bfe3;
$color-blue-2: #5390d9;
