/*  reset default styles */

h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p {
    margin: 0;
}

a {
    &:not([class]) {
        @include text-link();
    }
}

ul {
    @include reset-list();
}

img {
    vertical-align: top;
}

.title-type-1 {
    @include h1();
}

.title-type-2 {
    @include h2();
}

.title-type-3 {
    @include h3();
}

.title-type-4 {
    @include h4();
}

.title-type-5 {
    @include h5();
}

.title-type-6 {
    @include h6();
}
