.order-info {
    $this: &;

    &__header,
    &__details {
        padding: 30px 30px 0 30px;
    }

    &__details {
        padding-top: 0;
        padding-bottom: 40px;
    }

    &__row {
        display: flex;
        align-items: center;
    }

    &__row-item {
        & + & {
            margin-left: 30px;
        }
    }

    &__cite {
        display: flex;
        align-items: center;
    }

    &__cite-item {
        font-size: 18px;

        a {
            font-size: inherit;
            font-weight: 700;
        }

        & + & {
            margin-left: 30px;
        }

        & + #{$this}__cite-item--status {
            margin-left: 90px;
        }
    }

    &__table {
        @include scrollbars(5px, $color-dark-gray, $color-white);

        overflow-x: auto;
        overflow-y: scroll;
        display: block;
        max-height: 385px;
        margin-top: 30px;
        white-space: nowrap;
    }
}
