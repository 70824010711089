.form-input {
    $this: &;

    position: relative;
    display: block;
    width: 100%;

    &__control {
        width: 100%;
        padding: 10px 15px;
        border: 1px solid $color-light-grey;
        border-radius: 5px;
        color: $color-dark-gray;
        font-size: 14px;
        transition: border-color $transition-default;
        appearance: none;

        @include placeholder {
            color: $color-light-grey;
        }

        .is-invalid & {
            border-color: $color-danger;
        }

        .is-invalid &,
        .is-valid & {
            padding-right: 50px;
        }

        @include hover-focus {
            border: 1px solid rgba(42, 42, 42, 0.6);
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 22px;
        transform: translateY(-50%);
        pointer-events: none;
        fill: $color-light-grey;
    }

    &__btn {
        position: absolute;
        top: 50%;
        right: 17px;
        transform: translateY(-50%);
        fill: $color-light-grey;
    }

    &__validity-state-icon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        pointer-events: none;
    }

    &__support {
        position: absolute;
        top: 50%;
        left: 103%;
        transform: translateY(-50%);
        width: 220px;
        font-size: 14px;
    }

    &__support-btn {
        @include text-link-dashed();
    }

    &--search {
        #{$this}__control {
            padding-left: 50px;
        }
    }

    &--password {
        #{$this}__control {
            padding-right: 50px;
        }
    }
}
