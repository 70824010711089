.order-details {
    $this: &;

    max-width: 960px;

    &__item {
        display: flex;
        font-size: 18px;

        & + & {
            margin-top: 30px;
        }
    }

    &__label {
        flex: 1 0 20%;
        padding-right: 20px;
    }

    &__description {
        flex: 1 0 80%;
        font-weight: 700;
    }

    &__total {
        font-weight: 800;
    }
}
