.form-control {
    $this: &;

    &__header {
        margin-bottom: 10px;

        @include tablet {
            margin-bottom: 10px;
        }
    }

    &__label {
        display: block;
        font-family: $ff-circe;
        font-weight: 700;

        @include tablet {
            font-size: 14px;
        }
    }

    &__description {
        display: block;
        margin-top: 5px;
        font-size: 12px;

        @include tablet {
            font-size: 14px;
        }
    }

    &__errors {
        @include reset-list();

        margin-top: 10px;

        &:not(.filled) {
            display: none;
        }
    }

    &__error-item {
        color: $color-danger;
        font-size: 14px;

        & + & {
            margin-top: 3px;
        }
    }
}
