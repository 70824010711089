.filter-group {
    $this: &;

    display: flex;
    align-items: center;

    &__item {
        display: flex;
        flex: 1 0 20%;
        flex-wrap: wrap;
        align-items: center;

        & + & {
            margin-left: 115px;
        }
    }

    &__item-label {
        margin-right: 20px;
        font-size: 18px;
        font-weight: 700;
    }
}
