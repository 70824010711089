.responsive-media {
    position: relative;

    & > img,
    & > video {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
}

img,
video {
    max-width: 100%;
}
